import styled from '@emotion/styled';
import React from 'react';
import InvisalignBox from './../InvisalignBox';

const Braces = styled.section`
    position: relative;
    padding: 0 12px;

    @media (min-width: 1280px) {
        padding: 128px 0;
    }

    @media (min-width: 1900px) {
        padding: 180px 0;
    }
`;

export default function InvisalignPageSection() {
    return (
        <Braces>
            <InvisalignBox invisalignPage />
        </Braces>
    );
}

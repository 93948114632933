import React from 'react';
import CommonHero from '../components/layout/CommonHero';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import HowInvisalignWorks from '../components/Sections/HowInvisalignWorks';
import InvisalignPageSection from '../components/Sections/InvisalignPageSection';

export default function Invisalign() {
    return (
        <Layout>
            <Seo
                title="Invisalign"
                description="Invisalign treatment is the clear alternative to metal braces for kids, teens, and adults. Contact us today we are an Invisalign trained orthodontist."
            />
            <CommonHero title="invisalign" />
            <HowInvisalignWorks />
            <InvisalignPageSection />
        </Layout>
    );
}
